import { useState, useEffect } from "react"
import {
  fetchStream,
  fetchStreamViewers,
  fetchGamePlaying,
} from "../utils/twitch"

const useTwitch = streamers => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState("")
  const [filtered, setFiltered] = useState([])

  useEffect(() => {
    const x = []

    const func = async () => {
      await Promise.all(
        streamers.map(async streamer => {
          const response = await fetchStream(streamer.node.username)
          const streamViewers = await fetchStreamViewers(response.id)
          const game = await fetchGamePlaying(response.game_id)

          x.push({
            username: streamer.node.username,
            slug: streamer.node.slug,
            isLive: response.is_live,
            avatar: response.thumbnail_url,
            affiliated: streamer.node.affiliated,
            id: response.id,
            viewers: streamViewers.length ? streamViewers[0].viewer_count : 0,
            game: game ? game.name : "//",
          })
        })
      )
      setLoading(false)
      setData(x)
      setFiltered(x)
    }

    func()
  }, [])

  useEffect(() => {
    setFiltered(
      data.filter(({ username }) => {
        return username.toLowerCase().includes(query.toLowerCase())
      })
    )
  }, [query])

  return {
    data,
    query,
    setQuery,
    filtered,
    loading,
  }
}

export default useTwitch
