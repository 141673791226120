import styled from "styled-components"

export const SearchInput = styled.input`
  padding: 8px;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: transparent;
  color: ${props => props.theme.colors.white};
`

export const SearchWrapper = styled.div`
  padding-top: 56px;
  padding-bottom: 24px;
  display: grid;
  align-items: center;
  grid-column-gap: 24px;
  grid-row-gap: 8px;
  grid-template-columns: ${props =>
    props.extraCol ? "1fr 232px 264px" : "1fr 264px"};
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 24px;
  }
`

export const SearchWrapperWideInput = styled(SearchWrapper)`
  grid-template-columns: 1fr 403px;

  img {
    margin-bottom: 0;
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding-top: 24px;

    img {
      margin-bottom: 8px;
    }
  }
`
