import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import useTwitch from "../hooks/useTwitch"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Spinner from "../components/loader"
import TwitchWordmarkIcon from "../images/twich-wordmark.svg"
import StarIcon from "../images/star.svg"
import OfflineIcon from "../images/offline-icon.svg"
import OnlineIcon from "../images/online-icon.svg"
import { sortByLiveStatus } from "../utils/sorts"
import { Container } from "../components/styled/container"
import {
  SearchInput,
  SearchWrapperWideInput,
} from "../components/styled/search"
import { NoData } from "../components/styled/noData"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const StreamersCard = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 24px;
  align-items: center;
  padding: 16px;
  background-color: ${props =>
    props.isOdd
      ? props.theme.colors.gameCardBgLight
      : props.theme.colors.gameCardBg};
  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`

const StreamersCardTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.gameCardTitle};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const StreamersCardData = styled.p`
  color: ${props => props.theme.colors[props.color]};
  margin-bottom: 0;
`

const StreamStatus = styled.div`
  color: #fbfbfc;
  display: flex;
  align-items: center;
  line-height: 1.5;

  @media (max-width: 576px) {
    justify-content: center;
  }
`

const Streamers = ({ data }) => {
  const { query, setQuery, filtered, loading } = useTwitch(
    data.allStrapiStreamers.edges
  )

  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main loading={loading ? "true" : "false"}>
          <SEO
            title="Streamers - FGCharts"
            description={data.strapiSeo.streamerspage}
          />
          <SearchWrapperWideInput>
            <img src={TwitchWordmarkIcon} alt="twitch logo" title="twitch" />
            <SearchInput
              wideInput
              placeholder="Search featured Streamers"
              onChange={e => setQuery(e.target.value)}
              value={query}
            />
          </SearchWrapperWideInput>
          {loading ? (
            <Spinner />
          ) : filtered.length ? (
            filtered.sort(sortByLiveStatus).map((stream, index) => {
              return (
                <Link
                  to={`/streamers/${stream.slug}`}
                  style={{ textDecoration: "none" }}
                  key={index}
                >
                  <StreamersCard isOdd={Boolean(index % 2)}>
                    <img
                      src={stream.avatar}
                      alt="Streamers avatar"
                      style={{
                        width: 48,
                        height: 48,
                        borderRadius: "50%",
                        border: "2px solid #4960EA",
                        marginBottom: 0,
                        justifySelf: "center",
                      }}
                    />
                    <div>
                      <StreamersCardTitle>Username</StreamersCardTitle>
                      <StreamersCardData
                        style={{ textTransform: "capitalize" }}
                      >
                        {stream.username}
                      </StreamersCardData>
                    </div>
                    <div>
                      <StreamersCardTitle>Currently Playing</StreamersCardTitle>
                      <StreamersCardData color="yellow">
                        {stream.game}
                      </StreamersCardData>
                    </div>
                    <div>
                      <StreamersCardTitle>View Count</StreamersCardTitle>
                      <StreamersCardData color="green">
                        {stream.viewers}
                      </StreamersCardData>
                    </div>
                    <div>
                      <StreamersCardTitle>
                        Affiliated Streamer
                      </StreamersCardTitle>
                      <StreamersCardData color="#F19954">
                        {stream.affiliated ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={StarIcon}
                              alt="star icon"
                              style={{ marginBottom: 0, marginRight: 8 }}
                            />{" "}
                            Yes
                          </div>
                        ) : (
                          "No"
                        )}
                      </StreamersCardData>
                    </div>
                    <div>
                      <StreamersCardTitle>Live Status</StreamersCardTitle>
                      {stream.isLive ? (
                        <StreamStatus>
                          <img
                            src={OnlineIcon}
                            alt="Red circle icon, indicating that the streamer is online"
                            style={{
                              marginRight: 8,
                              marginBottom: 0,
                            }}
                          />
                          Online
                        </StreamStatus>
                      ) : (
                        <StreamStatus>
                          <img
                            src={OfflineIcon}
                            alt="Grey circle icon, indicating that the streamer is offline"
                            style={{
                              marginRight: 8,
                              marginBottom: 0,
                            }}
                          />
                          Offline
                        </StreamStatus>
                      )}
                    </div>
                  </StreamersCard>
                </Link>
              )
            })
          ) : (
            <NoData>
              There are currently no Streamers that fit the criteria.
            </NoData>
          )}
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiStreamers {
      edges {
        node {
          username
          slug
          affiliated
        }
      }
    }
    strapiSeo {
      streamerspage
    }
  }
`

export default Streamers
